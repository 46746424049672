import React, { useState, useEffect, useRef, useMemo } from "react"
import { graphql, Link } from "gatsby"
import axios from "axios"
import Cookies from "universal-cookie"
import { isLoggedIn, getUser } from "../../../services/auth"
import config from "../../../config/config"

import Layout from "../../../templates/Page"
import Header from "../../ContentBuilder/Header"
import SwiperList from "../../ContentBuilder/SwiperList"
import Subscribe from "../../Footer/subscribe"
import { Swiper, SwiperSlide } from "swiper/react"
import ShowMoreText from "react-show-more-text"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import FacadeRelatedItems from "./FacadeRelatedItems"
import {
  getRecentPages,
  setRecentPages,
} from "../../../services/account-services"
import RecentViews from "../../RecentViews"
import { connect } from "react-redux"
import { getBoardFromLocalStorage } from "../../../state/board"

const cookies = new Cookies()
var sent = false

const FacadeDetail = ({ data, currentBoards, dispatch }) => {
  const swiper_params = {
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-button-prev-unique",
      nextEl: ".swiper-button-next-unique",
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
      },
      1100: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }
  const [slideVariation, setSlideVariation] = useState(null)
  const changeSlideImage = slide => {
    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (!found && key.includes("Facade") && items[key].ID == facade.objectID && items[key].VariantID == slide.objectID) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
    setActiveObjectID(slide.objectID)
    setSlideVariation(slide)
  }
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)
  const [facade, setFacade] = useState({})
  const [globalConfig, setGlobalConfig] = useState({})
  const [activeObjectID, setActiveObjectID] = useState(null)
  const [isSaved, setIsSaved] = useState(false)
  var vid = null
  if (typeof window !== "undefined") {
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    vid = params.get("vid")
  }
  
  useEffect(() => {
    dispatch(getBoardFromLocalStorage())
  }, [])

  useEffect(() => {
    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (!found && key.includes("Facade") && items[key].ID == facade.objectID && items[key].VariantID == activeObjectID) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
    if(vid){
        setActiveObjectID(vid)
    }
  }, [currentBoards, facade, activeObjectID])
  
   var rrp = 0
    var image_path = ""
    var featured_designer = ""
    var featured_garage = ""
    var featured_storeys = ""
    var featured_Name = ""
    var _allVariations = []
    var formatted_rrp
    
  useEffect(() => {
    if (data) {
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig)
      setFacade(data.allSilverStripeDataObject.nodes[0].UndigitalFacade)
      let _item = data.allSilverStripeDataObject.nodes[0].UndigitalFacade
      if (typeof _item.FeaturedVariation !== "undefined") {
        let _variation = _item.FeaturedVariation.UndigitalFacadeVariation

        let found = false
        for (const board of currentBoards) {
          const items = JSON.parse(board.Items)
          for (const key in items) {
            if (!found && key.includes("Facade") && items[key].ID == _item.objectID && items[key].VariantID == _variation.objectID) {
              setIsSaved(true)
              found = true
            }
          }
        }
        if (!found) setIsSaved(false)
      }
      
    var FeaturedVariation = _item.FeaturedVariation
   
    if (FeaturedVariation) {
      _item.imagePosition =
        FeaturedVariation.UndigitalFacadeVariation.imagePosition
      rrp = FeaturedVariation.UndigitalFacadeVariation.rrPrice
      rrp = parseFloat(Math.round(rrp * 1000) / 1000).toFixed(2)
      formatted_rrp =
        "$" + rrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      image_path = FeaturedVariation.UndigitalFacadeVariation.featureImagePath
      featured_Name = FeaturedVariation.UndigitalFacadeVariation.name
      if (activeObjectID === null) {
        setActiveObjectID(FeaturedVariation.UndigitalFacadeVariation.objectID)
      }
      featured_designer =
        typeof FeaturedVariation.UndigitalFacadeVariation.Designer !== undefined
          ? FeaturedVariation.UndigitalFacadeVariation.Designer.UndigitalDesigner
            .name
          : ""
      featured_storeys =
        typeof FeaturedVariation.UndigitalFacadeVariation.Storeys !== undefined &&
          FeaturedVariation.UndigitalFacadeVariation.Storeys !== null
          ? FeaturedVariation.UndigitalFacadeVariation.Storeys.UndigitalStoreys
            .name
          : ""
      featured_garage =
        typeof FeaturedVariation.UndigitalFacadeVariation.Garage !== undefined
          ? FeaturedVariation.UndigitalFacadeVariation.Garage.UndigitalGarage.name
          : ""
    }

      
        const variations = _item.Variations
        const valid_status = ["Active"]
        if (typeof variations !== undefined && variations) {
          var i = 0
          for (; variations[i];) {
            let variation = variations[i].UndigitalFacadeVariation
            let _variation_id = variation.objectID
            if(_variation_id == vid){
                  setSlideVariation(variation)
            }
            let isValid = valid_status.includes(variation.status)
            if (isValid) {
              let variation_garageName =
                typeof variation.Garage !== undefined
                  ? variation.Garage.UndigitalGarage.name
                  : ""
              let variation_storeysName =
                typeof variation.Storeys !== undefined && variation.Storeys !== null
                  ? variation.Storeys.UndigitalStoreys.name
                  : ""
              let combinedName = []
              combinedName.push(variation.name)
              if (variation_garageName) {
                //combinedName.push(variation_garageName)
                variation.mixedGarage = variation_garageName
              }
              if (variation_storeysName) {
                //combinedName.push(variation_storeysName)
                variation.mixedStoreys = variation_storeysName
              }
              variation.combinedName = combinedName.join(", ")
              var rrpPrice = parseFloat(
                Math.round(variation.rrPrice * 1000) / 1000
              ).toFixed(2)
              variation.formattedPrice =
                "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

              _allVariations[i] = variation
            }
            i++
          }
        }
    }
  }, [])
  

  const [showCreateBoard, setShowCreateBoard] = useState(false)
  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  if (facade) {
    const recentPages = getRecentPages()
    if(vid){
        if(slideVariation && (typeof slideVariation.status !== 'undefined' && slideVariation.status == 'Active')){
            facade.variant = slideVariation   
            setRecentPages([
                { ...facade, className: 'Facade' },
                ...recentPages.filter(function (rp) {
                      return !((rp.className == "Facade" && rp.objectID == facade.objectID) && (typeof rp.variant !== 'undefined' && rp.variant.objectID == facade.variant.objectID))
              }),
            ])
        }
    }else{
        setRecentPages([
            { ...facade, className: 'Facade' },
            ...recentPages.filter(function (rp) {
                  return !((rp.className == "Facade" && rp.objectID == facade.objectID) && (typeof rp.variant == 'undefined'))
          }),
        ])
    }
  }

  const [customData, setCustomData] = useState(slideVariation)
  const [loaded, setLoaded] = useState(false)
  const imgElement = useRef(null)
  /*
  useEffect(() => {
      console.log(slideVariation)
    setCustomData(slideVariation)
  }, [slideVariation])
*/
  const createBoardPopup = useMemo(() => {
    return <CreateBoardModal
      show={showCreateBoard}
      handleClose={() => {
        setShowCreateBoard(false)
      }}
      objectClass="Facade"
      objectID={facade.objectID}
      variantID={activeObjectID}
    />
  }, [currentBoards, showCreateBoard, facade, activeObjectID])

  return (
    <Layout hideHeader>
      {createBoardPopup}
      <Header isLogin />
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/facades/">Facades</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Gable Facade
            </li>
          </ol>
        </nav>

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title">{facade.name}</h1>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link">
                <div
                  className="d-flex align-items-center"
                  onClick={() => showAddBoard()}
                >
                  {isSaved ? "Added to board" : "Add to board"}
                  {isSaved ? (
                    <svg
                      className="text-black ml-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                    >
                      <path
                        id="Path_878"
                        data-name="Path 878"
                        d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                        transform="translate(21.982 6.975)"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      focusable="false"
                      width="30px"
                      height="30px"
                      className="text-black ml-3"
                    >
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="wrap-section row mt-5">
          <div className="col-md-8">
            <div className="d-flex flex-column flex-sm-row justify-content-between wrap-section border-bottom-4px feature-title">
              <h3 className="mb-0">
                {slideVariation ? slideVariation.name : featured_Name}
              </h3>
            </div>
            <div
              className={`landing-detail-image-wrap mb-4 ${slideVariation
                  ? slideVariation.imagePosition
                  : facade.imagePosition
                } ${!slideVariation && !image_path ? " no-image" : ""}`}
            >
              {(slideVariation || image_path) && (
                <a
                  href={
                    slideVariation
                      ? slideVariation.featureImagePath
                      : image_path
                  }
                  className="d-block main-img pointer"
                  data-fancybox="gallery"
                  data-width={
                    loaded && imgElement.current.src.slice(-3) === "svg"
                      ? imgElement.current.naturalWidth * 9
                      : ""
                  }
                  data-height={
                    loaded && imgElement.current.src.slice(-3) === "svg"
                      ? imgElement.current.naturalHeight * 9
                      : ""
                  }
                >
                  <img
                    src={
                      slideVariation
                        ? slideVariation.featureImagePath
                        : image_path
                    }
                    alt=""
                    ref={imgElement}
                    onLoad={() => setLoaded(true)}
                  />
                </a>
              )}
            </div>

            {_allVariations.length > 1 && (
              <div className="other-available-wrap">
                <h3 className="main-title">Other available options: </h3>
                <div className="other-available">
                  <Swiper navigation {...swiper_params}>
                    {_allVariations.map((variation, idx) => (
                      <SwiperSlide key={`variation${idx}`}>
                        <div
                          onClick={() => changeSlideImage(variation)}
                          className="product-items clickable"
                        >
                          <div
                            className={`product-items-thumb ${variation.objectID === activeObjectID
                                ? "active"
                                : ""
                              } ${variation.imagePosition}`}
                          >
                            <img
                              alt=""
                              src={variation.featureImagePath}
                              alt=""
                            />
                          </div>
                          <p className="product-items-desc">
                            {variation.combinedName}
                            {globalConfig.hidePricingGlobally == 0 && (
                              <>
                                {variation.combinedName ? " |" : ""}{" "}
                                {variation.formattedPrice}
                              </>
                            )}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="swiper-button-prev-unique"></div>
                  <div className="swiper-button-next-unique"></div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <h3 className="mb-1 border-bottom-4px">Information</h3>
            <div className="list-group list-group-flush mb-4">
              {globalConfig.hidePricingGlobally == 0 && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Price from</h5>
                    </div>
                    <div className="col-auto">
                      <p className="h4">
                        {slideVariation
                          ? slideVariation.formattedPrice
                          : formatted_rrp}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {featured_designer && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Designer</h5>
                    </div>
                    <div className="col-auto">
                      <p id="variation_designer" className="font-weight-medium">
                        {featured_designer}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {(featured_storeys || slideVariation) && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Storeys</h5>
                    </div>
                    <div className="col-auto">
                      <p id="variation_storeys" className="font-weight-medium">
                        {slideVariation
                          ? slideVariation.mixedStoreys
                          : featured_storeys}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {(featured_garage || slideVariation) && (
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Garages</h5>
                    </div>
                    <div className="col-auto">
                      <p id="variation_garage" className="font-weight-medium">
                        {slideVariation
                          ? slideVariation.mixedGarage
                          : featured_garage}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="list-group-item px-0">
                <h5 className="mb-0">About</h5>
                {facade.about && (
                  <ShowMoreText
                    more="Read more"
                    less="Read less"
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    {facade.about}
                  </ShowMoreText>
                )}
              </div>
            </div>

            <div className="row gutters-10 mt-30">
              <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                <button
                  type="button"
                  className="btn btn-outline-black btn-block btn-lg px-2 my-1"
                >
                  <div
                    className="d-flex align-items-middle justify-content-center"
                    onClick={() => showAddBoard()}
                  >
                    <svg
                      focusable="false"
                      width="24px"
                      height="24px"
                      className="mr-2"
                    >
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                    {isSaved ? "Added to board" : "Add to board"}
                  </div>
                </button>
              </div>
              <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                <a
                  href="/contact-us/"
                  className="btn btn-black btn-block btn-lg px-2 my-1"
                >
                  Enquire now
                </a>
              </div>
            </div>
          </div>
        </div>

        <FacadeRelatedItems id={facade.objectID} />
        <RecentViews />
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(
      className: { eq: "Undigital__Objects__GlobalConfig" }
    ) {
      UndigitalGlobalConfig {
        hidePricingGlobally
      }
    }
    allSilverStripeDataObject(
      filter: {
        UndigitalFacade: {
          urlSegment: { eq: $slug }
          status: { in: ["Active", "Draft"] }
        }
      }
    ) {
      nodes {
        UndigitalFacade {
          name
          about
          urlSegment
          objectID
          Variations {
            UndigitalFacadeVariation {
              status
              rrPrice
              featureImagePath
              imagePosition
              name
              objectID
              Designer {
                UndigitalDesigner {
                  name
                }
              }
              Garage {
                UndigitalGarage {
                  name
                }
              }
              Storeys {
                UndigitalStoreys {
                  name
                }
              }
            }
          }
          FeaturedVariation {
            UndigitalFacadeVariation {
              featureImagePath
              imagePosition
              rrPrice
              name
              objectID
              Designer {
                UndigitalDesigner {
                  name
                }
              }
              Garage {
                UndigitalGarage {
                  name
                }
              }
              Storeys {
                UndigitalStoreys {
                  name
                  objectID
                }
              }
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = (state) => ({
  currentBoards: state.board.all,
})

export default connect(mapStateToProps, null)(FacadeDetail)
